import { Link } from '@kaliber/routing'

import { useTheme } from '/machinery/Theme'

import { Icon } from '/sub/Icon'

import styles from './ButtonCircle.css'

export function ButtonCircle({ children, onClick, icon, dataX }) {
  return <CircleBase type='button' {...{ icon, children, onClick, dataX }} />
}
export function ButtonCircleLink({ children, to, icon, dataX }) {
  return <CircleBase Element={LinkWrapper} {...{ icon, children, to, dataX }} />
}

function LinkWrapper({ to, children, dataX, ...props }) {
  return <Link {...{ to, children }} anchorProps={{ ...props, 'data-x': dataX }} />
}

function CircleBase({ children, icon, dataX, Element = undefined, ...props }) {
  const BaseElement = Element ?? 'button'
  const theme = useTheme()

  return (
    <BaseElement
      data-x={dataX}
      className={cx(
        styles.componentCircleBase,
        icon && styles.hasIcon,
        theme.background,
        theme.accent
      )}
      {...props}
    >
      <span className={styles.body}>{children}</span>

      {icon && (
        <span className={styles.icon}>
          <Icon {...{ icon }} />
        </span>
      )}
    </BaseElement>
  )
}
