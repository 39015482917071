import { I18nProvider } from '@kaliber/i18n'
import { i18n } from '/i18n'
import { routeMap } from '/routeMap'
import { LinkText } from '/sub/buildingBlocks/Link'
import { Container } from '/sub/layout/Container'
import { HeadingUppercaseXxl } from '/sub/typography/Heading'
import { TextMd, TextLg } from '/sub/typography/Text'
import { ThemeProvider } from '/machinery/Theme'
import theme from '/themes/red-light.css'
import styles from './NotFound.css'

export function NotFound() {
  return (
    <I18nProvider value={i18n} language='nl'>
      <ThemeProvider {...{ theme }}>
        <div className={cx(styles.page, theme.root)}>
          <Container>
            <div className={styles.layout}>
              <HeadingUppercaseXxl h={1} layoutClassName={styles.title}>OH NEE! ONTSPOORD!</HeadingUppercaseXxl>
              <TextLg layoutClassName={styles.subtitle}>Het sein staat op rood</TextLg>
              <TextMd layoutClassName={styles.text}>
                <p>Door een verkeerde wissel zijn we even het spoor bijster. We kunnen deze pagina niet vinden.</p>
                <p>Je kunt het menu gebruiken om weer op het juiste spoor te komen of vind je weg opnieuw via <LinkText to={routeMap.home({ language: 'nl' })}>de homepagina</LinkText>.</p>
              </TextMd>
            </div>
          </Container>
        </div>
      </ThemeProvider>
    </I18nProvider>
  )
}
