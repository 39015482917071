import { useTheme } from '/machinery/Theme'
import { Link } from '@kaliber/routing'
import { Icon } from '/sub/Icon'
import iconArrowRightSmall from '/images/icons/arrow-right-small.raw.svg'
import styles from './ButtonListLink.css'


export function ButtonListLink({ children, dataX, to = undefined, onClick = undefined }) {
  const theme = useTheme()

  return (
    <Link
      anchorProps={{ className: cx(styles.component, theme.accent), 'data-x': dataX, onClick }}
      {...{ to }}
    >
      <span className={styles.text}>
        { children }
      </span>
      <span className={styles.iconRight}>
        <Icon icon={iconArrowRightSmall} />
      </span>
    </Link>
  )
}
