import styles from './Floors.css'
import { useNavigate, useRoutes, useRouting } from '@kaliber/routing'
import { usePageRouteData } from '/machinery/PageRouteData'
import { Floor } from './Floor'
import { DraggableMap } from '/sub/draggableMap/DraggableMap'
import { useMediaQuery } from '/machinery/useMediaQuery'
import { viewportMd } from '/cssGlobal/media.css'

export function Floors({ floors }) {
  const { matchRoute } = useRouting()
  /** @type {import('/routeMap').routeMap['storyline']} */
  const routes = useRoutes()
  const navigate = useNavigate()
  const { data } = usePageRouteData()
  const { floor: selectedFloor = null } = data || {}
  const isViewportMd = useMediaQuery({ query: viewportMd, initialValue: false })

  return (
    <div className={styles.component}>
      <div className={styles.map}>
        {selectedFloor
          ? setFloorRouteContext(
            <Floor
              active
              visible
              showDetailOnClick={!selectedFloor.showTeaser}
              image={selectedFloor.image}
            />
          ) : (
            <DraggableMap
              pins={[]}
              enabled
              offsetX={isViewportMd ? 160 : 0}
              offsetY={isViewportMd ? 0 : 180}
              image={floors[0].image}
            />
          )
        }
      </div>
    </div>
  )

  function setFloorRouteContext(children) {
    // This makes the active floor aware of the route, this simplifies navigation inside
    return matchRoute(routes.floorPlan.floor, children) || children
  }
}
