import styles from './Text.css'

export function TextLg({ children, layoutClassName = undefined }) {
  return <TextBase className={styles.componentLg} {...{ children, layoutClassName }} />
}

export function TextMd({ children, layoutClassName = undefined }) {
  return <TextBase className={styles.componentMd} {...{ children, layoutClassName }} />
}

export function TextSm({ children, layoutClassName = undefined }) {
  return <TextBase className={styles.componentSm} {...{ children, layoutClassName }} />
}

function TextBase({ className, children, layoutClassName }) {
  return (
    <div className={cx(styles.componentBase, className, layoutClassName)}>{children}</div>
  )
}
