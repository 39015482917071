import groq from 'groq'
import { I18nProvider } from '@kaliber/i18n'
import { i18n } from '/i18n'
import { usePageRouteData } from '/machinery/PageRouteData'
import { Section } from '/sub/layout/Section'
import { Container } from '/sub/layout/Container'
import { HeadingUppercaseXl } from '/sub/typography/Heading'
import { RichText } from '/sub/RichText'
import styles from './Page.css'

Page.fetchData = async ({ client, params: { pageSlug, language } }) => {
  const page = await client.fetch(
    groq`*[slug.current == $pageSlug && _type == 'page' && language == $language][0]`,
    { pageSlug, language }
  )
  return page && { page }
}

export function Page() {
  const { data } = usePageRouteData()
  const { title = '', text = [], language = 'nl' } = data.page || {}

  return (
    <I18nProvider value={i18n} {...{ language }} >
      <div className={styles.page}>
        <header className={styles.hero}>
          <Container>
            <HeadingUppercaseXl h={1}>{title}</HeadingUppercaseXl>
          </Container>
        </header>

        <Section>
          <Container>
            <RichText blocks={text.blocks} />
          </Container>
        </Section>
      </div>
    </I18nProvider>
  )
}
