import { useSpring, useTransition, animated } from '@react-spring/web'
import { useGesture } from '@use-gesture/react'
import { useElementSize } from '@kaliber/use-element-size'
import { lerp, unlerp, clamp } from  '@kaliber/math'

import { useMapPosition } from '/machinery/MapPosition'
import { pushToDataLayer } from '/machinery/tracking/pushToDataLayer'

import { Image } from '/sub/Image'
import { Pin } from '/sub/Pin'
import { Icon } from '/sub/Icon'

import styles from './DraggableMap.css'

import minIcon from '/images/icons/min.raw.svg'
import plusIcon from '/images/icons/plus.raw.svg'

const minZoomLevel = -1
const maxZoomLevel = 2

export function DraggableMap({
  image,
  offsetX,
  offsetY,
  enabled = true,
  pins = [],
  onPinClick = undefined,
  selectedPin = undefined
}) {
  const [zoomLevel, setZoomLevel] = React.useState(0)
  const { mapRef, bind, style } = useDragAndZoomGestures({ enabled, offsetX, offsetY, selectedPin, zoomLevel, maxZoomLevel, onZoomChange: handleZoom })

  return (
    <div className={styles.component} {...bind()}>
      <animated.div className={styles.draggableMap} {...{ style }}>
        <div ref={mapRef} className={styles.map}>
          <Image draggable={false} quality={90} {...{ image }} />
          <Pins onPinClick={handlePinClick} layoutClassName={styles.pinsOverlay} {...{ pins, selectedPin }} />
        </div>
      </animated.div>

      <ZoomButtons onZoomChange={handleZoom} layoutClassName={styles.zoomButtonsLayout} {...{ zoomLevel, minZoomLevel, maxZoomLevel }} />
    </div>
  )

  function handleZoom(zoomLevel) {
    setZoomLevel(clamp({ min: minZoomLevel, max: maxZoomLevel, input: zoomLevel }))

    pushToDataLayer({
      event: 'interaction',
      event_data: {
        metadata: {
          interaction: {
            title: 'map-zoom',
            type: 'zoomed'
          }
        }
      }
    })
  }

  function handlePinClick(pin) {
    onPinClick(pin)
  }
}

function Pins({ pins, onPinClick, selectedPin, layoutClassName = undefined }) {
  const pinTransitions = useTransition(
    pins,
    {
      keys: pin => pin.id,
      from: { opacity: 0, y: -50 },
      enter: { opacity: 1, y: 0 },
      leave: { opacity: 0, y: -25 },
      trail: 100
    }
  )

  return (
    <div className={cx(styles.componentPins, layoutClassName)}>
      {pinTransitions((style, pin) => {
        const { title, floorLocation: { location: { x, y } } } = pin
        return (
          <animated.div
            className={styles.floorPin}
            style={{
              left: `${x}%`,
              top: `${y}%`,
              transform: style.y.to(y => `translate3d(0, ${y}px, 0)`),
              opacity: style.opacity
            }}
          >
            <Pin
              dataX='select-story'
              active={selectedPin && pin.id === selectedPin.id}
              onClick={handlePinClickFor(pin)}
              {...{ title }}
            />
          </animated.div>
        )
      })}
    </div>
  )

  function handlePinClickFor(pin) {
    return (e) => {
      e.stopPropagation()
      onPinClick(pin)
    }
  }
}

function ZoomButtons({ onZoomChange, zoomLevel, minZoomLevel, maxZoomLevel, layoutClassName = undefined }) {
  return (
    <div className={cx(styles.componentZoomButtons, layoutClassName)}>
      <ZoomButton disabled={zoomLevel === minZoomLevel} zoomDirection={zoomLevel - 1} icon={minIcon} {...{ onZoomChange }} />
      <ZoomButton disabled={zoomLevel === maxZoomLevel} zoomDirection={zoomLevel + 1} icon={plusIcon} {...{ onZoomChange }} />
    </div>
  )
}

function ZoomButton({ disabled, onZoomChange, zoomDirection, icon }) {
  return (
    <button onClick={() => onZoomChange(zoomDirection)} className={styles.componentZoomButton} {...{ disabled }}>
      <span className={cx(styles.iconContainer, disabled && styles.disabled)}>
        <Icon {...{ icon }} />
      </span>
    </button>
  )
}

function useDragAndZoomGestures({ enabled, offsetX, offsetY, selectedPin, zoomLevel, maxZoomLevel, onZoomChange }) {
  const { size: mapSize, ref: mapRef } = useElementSize()
  const { getPosition, setPosition } = useMapPosition()
  const position = getPosition()

  const zoomScale = 2 ** lerp({ start: 0, end: 2, input: zoomLevel / maxZoomLevel })

  const [style, api] = useSpring(() => ({
    x: position.x,
    y: position.y,
    scale: zoomScale,
    onRest: ({ value }) => setPosition({ x: value.x, y: value.y })
  }))

  React.useEffect(
    () => {
      api.start({ scale: zoomScale })
    },
    [api, zoomScale]
  )

  React.useEffect(
    () => {
      if (!selectedPin || window.history?.state?.preventPinFocus) return
      const { floorLocation: { location: { x, y } } } = selectedPin
      const coordinate = getMapLocationForPin({ x, y, mapSize })
      api.start({ x: coordinate.x - offsetX, y: coordinate.y - offsetY })
    },
    [selectedPin, mapSize, api, offsetX, offsetY]
  )

const bind = useGesture(
    {
      onDrag({ offset: [ox, oy] }) {
        api.start(
          {
            x: ox,
            y: oy,
          }
        )
      },
      onPinch({ delta: [deltaX] }) {
        const newZoom = zoomLevel + (deltaX * 0.01)
        onZoomChange(clamp({ min: 0, max: maxZoomLevel, input: newZoom }))
      },
    },
    {
      drag: {
        from: () => [style?.x.get(), style?.y.get()],
        bounds: {
          left: mapSize.width * -0.5 * zoomScale,
          right: mapSize.width * 0.5 * zoomScale,
          top: mapSize.height * -0.5 * zoomScale,
          bottom: mapSize.height * 0.5 * zoomScale,
        },
        rubberband: 0.5,
        enabled
      }
    }
  )

  return { mapRef, bind, style }
}

function getMapLocationForPin({ x, y, mapSize }) {
  return {
    x: lerp({
      start: 0.5 * mapSize.width,
      end: -0.5 * mapSize.width,
      input: unlerp({ start: 0, end: 100, input: x })
    }),
    y: lerp({
      start: 0.5 * mapSize.height,
      end: -0.5 * mapSize.height,
      input: unlerp({ start: 0, end: 100, input: y })
    })
  }
}
