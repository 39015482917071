import WebMercatorViewport from '@math.gl/web-mercator'
import { routeMap } from '/routeMap'
import { Map } from '/sub/map/Map'
import { StoryTeaserAnimated } from '/sub/story/StoryTeaser'
import styles from './Overview.css'
import { usePageRouteData } from '/machinery/PageRouteData'
import { Marker } from '/sub/map/Marker'
import groq from 'groq'
import { useNavigate, useRoutes } from '@kaliber/routing'

const ZOOMED_IN_ZOOM = 19
const ZOOMED_OUT_ZOOM = 14
const StoryMarkersMemo = React.memo(StoryMarkers)

Overview.fetchGeoStories = async function fetchGeoStories(client, storyline) {
  return client.fetch(
    groq`
      *[_type == 'geoStory' && storyline._ref == $id]
      {
        ...,
        'id': _id,
        'slug': slug.current,
        'geoLocation': {
          'latitude': geoLocation.lat,
          'longitude': geoLocation.lng
        },
        chapters[] {
          ...,
          'id': _key,
          image { ..., asset-> },
          image360 {
            ...,
            poster { ..., asset-> },
            image { ..., asset-> }
          },
          video {
            ...,
            'src': src.asset->url,
            poster { ..., asset-> }
          },
        }
      } |
      order(documentOrder desc)
    `,
    { id: storyline.id })
}

Overview.fetchStoryData = function fetchData({ params: { storySlug }, parentData: { stories } }) {
  const story = stories.find(x => x.slug === storySlug)
  if (!story) return null

  return { story }
}

export function Overview({ width, height, stories, inert }) {
  const { data } = usePageRouteData()
  const { story: selectedStory = null, storyline = null } = data || {}
  const routes = useRoutes()
  const navigate = useNavigate()

  const pitch = selectedStory ? 35 : 0
  const [viewport, setViewport] = React.useState(
    () => ({
      pitch,
      zoom: selectedStory ? ZOOMED_IN_ZOOM : ZOOMED_OUT_ZOOM,
      ...(selectedStory ? selectedStory.geoLocation : getCenter(stories.map(x => x.geoLocation)))
    })
  )

  // Whenever the latLng changes, update the map accordingly
  React.useEffect(
    () => {
      if (inert || !width || !height) return

      if (!selectedStory || window.history.state.preventPinFocus) return

      if (selectedStory) {
        setViewport(viewport => ({
          ...viewport,
          ...selectedStory.geoLocation,
          zoom: ZOOMED_IN_ZOOM,
          pitch: 35,
        }))
      } else {
        setViewport(viewport => {
          const boundingBox = calculateBoundingBox(stories.map(x => x.geoLocation))
          const mercatorViewport = new WebMercatorViewport({ ...viewport, width, height })
          const bounds = mercatorViewport.fitBounds(boundingBox, {
            padding: Math.min(window.innerWidth * 0.2, window.innerHeight * 0.2)
          })

          return {
            ...viewport,
            ...bounds,
            zoom: Math.min(viewport.zoom, bounds.zoom, ZOOMED_OUT_ZOOM),
          }
        })
      }
    },
    [inert, width, height, stories, selectedStory]
  )

  return (
    <Map onViewportChange={handleViewportChange} {...{ viewport }}>
      <div className={styles.component}>
        <StoryMarkersMemo
          onSelectStory={selectStory}
          {...{ stories, selectedStory }}
        />
        <div className={styles.storyTeaser}>
          <StoryTeaserAnimated story={selectedStory} storyContainerRoute={routes.geo} onDismiss={handleStoryDismiss} />
        </div>
      </div>
    </Map>
  )

  function selectStory(story) {
    navigate(routes.geo.story({ storySlug: story.slug }), { state: { preventPinFocus: true } })
  }

  function handleViewportChange(viewport) {
    setViewport(viewport)
  }

  function handleStoryDismiss() {
    navigate(routeMap.storyline({ storylineSlug: storyline.slug }), { replace: true })
  }
}


function StoryMarkers({ selectedStory, stories, onSelectStory }) {
  return (
    stories.map(story => {
      const { latitude, longitude } = story.geoLocation
      return (
        <Marker
          key={story.id}
          active={story.id === selectedStory?.id}
          title={story.title}
          onClick={() => onSelectStory(story)}
          dataX='select-story'
          {...{ latitude, longitude }}
        />
      )
    })
  )
}


function calculateBoundingBox(latLngs) {
  const lats = latLngs.map(x => x.latitude)
  const lngs = latLngs.map(x => x.longitude)
  const minLat = Math.min(...lats)
  const maxLat = Math.max(...lats)
  const minLng = Math.min(...lngs)
  const maxLng = Math.max(...lngs)
  return [[minLng, minLat], [maxLng, maxLat]]
}

function getCenter(latLngs) {
  const [[minLng, minLat], [maxLng, maxLat]] = calculateBoundingBox(latLngs)
  return { latitude: (minLat + maxLat) / 2, longitude: (minLng + maxLng) / 2 }
}
