import { reportError } from '/machinery/reportError'
import styles from './Lottie.css'

export function Lottie({ json, onLoad, layoutClassName }) {
  const { animation, ref: elementRef } = useLottieAnimation({ json })

  React.useEffect(
    () => {
      if (!animation) return
      return animation.addEventListener('DOMLoaded', onLoad)
    },
    [animation, onLoad]
  )

  return (
    <div className={cx(styles.component, layoutClassName)}>
      <div className={styles.lottie} ref={elementRef} />
    </div>
  )
}

function useLottieAnimation({ json, options: { renderer = 'svg', loop =  true, autoplay = true } = {} }) {
  const lottie = useLottie()
  const elementRef = React.useRef(null)

  const [animation, setAnimation] = React.useState(null)

  React.useEffect(
    () => {
      if (!lottie || !json) return

      const lottieInstance = lottie.loadAnimation({
        container: elementRef.current,
        animationData: JSON.parse(json),
        renderer,
        loop,
        autoplay
      })

      setAnimation(lottieInstance)

      return () => {
        setAnimation(null)

        // We don't immediately call destroy(), to give other effects the
        // opportunity to clean up, without throwing errors.
        setTimeout(() => { lottieInstance.destroy() })
      }
    },
    [lottie, json, loop, autoplay, renderer]
  )

  return { animation, ref: elementRef }
}

function useLottie() {
  const [lottie, setLottie] = React.useState(null)

  import('lottie-web')
    .then(module => { setLottie(module.default) })
    .catch(reportError)

  return lottie
}
