export const i18n = {
  back: {
    nl: 'Terug',
    en: 'Back'
  },
  'back-to-spoorwegmuseum': {
    nl: 'Naar Spoorwegmuseum.nl',
    en: 'To Spoorwegmuseum.nl'
  },
  'open-story': {
    nl: 'Open verhaal',
    en: 'Open story'
  },
  'dismiss-story': {
    nl: 'Verhaal deselecteren',
    en: 'Deselect story'
  },
  'close-story': {
    nl: 'Sluit verhaal',
    en: 'Close story'
  }
}
