import cx from 'classnames'

import styles from './Heading.css'


export function HeadingSm({ children, h = undefined, layoutClassName = undefined }) {
  return <HeadingBase {...{ h, children, layoutClassName }} className={styles.componentSm} />
}

export function HeadingMd({ children, h = undefined, layoutClassName = undefined }) {
  return <HeadingBase {...{ h, children, layoutClassName }} className={styles.componentMd} />
}

export function HeadingUppercaseLg({ children, h = undefined, layoutClassName = undefined }) {
  return <HeadingBase {...{ h, children, layoutClassName }} className={styles.componentUppercaseLg} />
}

export function HeadingUppercaseXl({ children, h = undefined, layoutClassName = undefined }) {
  return <HeadingBase {...{ h, children, layoutClassName }} className={styles.componentUppercaseXl} />
}

export function HeadingUppercaseXxl({ children, h = undefined, layoutClassName = undefined }) {
  return <HeadingBase {...{ h, children, layoutClassName }} className={styles.componentUppercaseXxl} />
}

function HeadingBase({ children, className, layoutClassName, h }) {
  const HBase = h ? `h${h}` : 'strong'

  // @ts-ignore
  return <HBase className={cx(styles.componentBase, className, layoutClassName)}>{children}</HBase>
}
