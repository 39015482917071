import styles from './ChapterLink.css'
import { useTheme } from '/machinery/Theme'
import { ButtonAccent } from '/sub/button/Button'
import { HeadingUppercaseXl } from '/sub/typography/Heading'

export function ChapterLink({ title, link, layoutClassName, onReadyChange }) {
  const theme = useTheme()
  React.useEffect(() => onReadyChange(true), [onReadyChange])

  return (
    <div className={cx(styles.component, theme.background, theme.accent, layoutClassName)}>
      <HeadingUppercaseXl>{title}</HeadingUppercaseXl>
      <ButtonAccent to={link.url} dataX='link'>
        {link.title}
      </ButtonAccent>
    </div>
  )
}
