import { reportError } from '/machinery/reportError'
import { Image } from '/sub/Image'
import { HeadingUppercaseXl } from '/sub/typography/Heading'
import { ThreeSixtyImage } from '/sub/ThreeSixtyImage'
import { ModalFullscreen } from '/sub/modal/Modal'
import { requestDeviceOrientationEventPermission as requestPermission } from '/machinery/requestDeviceOrientationEventPermission'
import { ButtonCircle } from '/sub/button/ButtonCircle'
import styles from './Chapter360.css'
import icon360 from '/images/icons/360.raw.svg'

export function Chapter360({ title, image360, layoutClassName, onReadyChange, onRunningChange }) {
  const [threeSixtyModalVisible, setThreeSixtyModalVisible] = React.useState(false)

  return (
    <div className={cx(styles.component, layoutClassName)}>
      <div className={styles.image}>
        <Image
          cover
          image={image360.poster}
          onLoad={() => onReadyChange(true)}
        />
      </div>

      <div className={styles.title}>
        <HeadingUppercaseXl>{title}</HeadingUppercaseXl>
      </div>

      <div className={styles.button}>
        <ButtonCircle onClick={openThreeSixtyModal} icon={icon360} dataX='open-360-modal'>
          Bekijk 360° foto
        </ButtonCircle>
      </div>

      <ModalFullscreen visible={threeSixtyModalVisible} onClose={closeThreeSixtyModal}>
        <ThreeSixtyImage image={image360.image} />
      </ModalFullscreen>
    </div>
  )

  async function openThreeSixtyModal() {
    try {
      onRunningChange(false)
      await requestPermission()
    } catch (error) {
      reportError(error)
    } finally {
      setThreeSixtyModalVisible(true)
    }
  }

  function closeThreeSixtyModal() {
    setThreeSixtyModalVisible(false)
    onRunningChange(true)
  }
}
