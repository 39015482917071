import BlockContent from '@sanity/block-content-to-react'
import { LinkText as Link } from '/sub/buildingBlocks/Link'
import { ListRegular, ListNumbered } from '/sub/List'
import styles from './RichText.css'

const serializers = {
  marks: {
    internalLink: ({ mark, children }) => <Link dataX='link-to-internal-page' to={mark.internalLink}>{children}</Link>,
    link: ({ mark, children }) => <a data-x='link-to-external-page' href={mark.href}>{children}</a>
  },
  list: ({ type, children }) => {
    switch (type) {
      case 'number':
        return <ListNumbered>{children}</ListNumbered>
      case 'bullet':
        return <ListRegular>{children}</ListRegular>
      default:
        if (process.env.NODE_ENV !== 'production') console.warn(`List type '${type}' missing`)
        return <ListRegular>{children}</ListRegular>
    }
  }
}

export function RichText({ blocks = [] }) {
  return (
    <div className={styles.component}>
      <BlockContent {...{ blocks, serializers }} />
    </div>
  )
}
