import ReactDOM from 'react-dom'
import { useSpring, animated, config } from '@react-spring/web'
import { useDrag } from '@use-gesture/react'
import { useElementSize } from '@kaliber/use-element-size'
import composeRefs from '@seznam/compose-react-refs'
import { useTheme } from '/machinery/Theme'
import { usePortal } from '/machinery/usePortal'
import { usePreventOverscroll } from '/machinery/usePreventOverscroll'
import { deriveDirectionSign } from '/machinery/deriveDirectionSign'
import { Icon } from '/sub/Icon'
import iconPull from '/images/icons/menu.raw.svg'
import styles from './Drawer.css'

const springConfig = { ...config.stiff, tension: 300 }
const Y_OPENED = 0
const Y_CLOSED = 90

export { DrawerPortal as Drawer }

function DrawerPortal({ children, visible, open, onOpen, onClose }) {
  const portalNode = usePortal('_rootDrawer')

  return portalNode
    ? ReactDOM.createPortal(<Drawer {...{ children, visible, open, onOpen, onClose }} />, portalNode)
    : <Drawer {...{ children, visible, open, onOpen, onClose }} />
}

function Drawer({ children, visible, open, onOpen, onClose }) {
  const theme = useTheme()
  const [delayFirstAnimation, setDelayFirstAnimation] = React.useState(true)
  const { size: { height }, ref: sizeRef } = useElementSize()
  const { ref: preventOverscrollRef } = usePreventOverscroll()
  const velocityRef = React.useRef(0)
  const [{ y }, api] = useSpring(() => ({
    y: visible ? Y_OPENED : 100,
    immediate: true
  }))

  const bind = useDrag(({ delta, offset, initial, velocity, down, tap, event }) => {
    if (down) handleDrag({ y: offset[1] })
    else if (tap) handleTap(event)
    else handleRelease({ offset: offset[1] - initial[1], velocity: velocity[1] / height * 100 * Math.sign(delta[1]) })
  }, {
    axis: 'y',
    rubberband: 0.1,
    from: () => [0, y.get() / 100 * height],
    filterTaps: true,
    bounds: {
      top: Y_OPENED / 100 * height,
      bottom: Y_CLOSED / 100 * height,
    },
  })

  React.useEffect(
    () => {
      api.start({
        y: (
          (visible && open) ? 0 :
          (visible && !open) ? 90 :
          100
        ),
        // We delay the first collapse, to show the user there's a panel they can interact with:
        delay: delayFirstAnimation ? 300 : 0,
        immediate: false,
        config: { ...springConfig, velocity: velocityRef.current },
        onRest() {
          if (delayFirstAnimation) setDelayFirstAnimation(false)
        }
      })
    },
    [api, visible, open, delayFirstAnimation]
  )

  return (
    <div ref={composeRefs(sizeRef, preventOverscrollRef)} className={styles.component_root}>
      <animated.div
        className={cx(styles.panel, theme.root, theme.accent)}
        style={{
          transform: y.to(y => `translate3d(0, ${y}%, 0)`)
        }}
      >
        <button
          data-x='open-drawer'
          className={cx(styles.handle, !open && styles.relativeToParent, open && styles.isOpened)}
          {...bind()}
        >
          <div className={styles.icon}>
            <Icon icon={iconPull} label="Pull" />
          </div>
        </button>
        <div className={styles.content}>{children}</div>
      </animated.div>
    </div>
  )

  function handleDrag({ y }) {
    api.start({
      y: y / height * 100,
      immediate: true,
      config: { ...springConfig, velocity: 0 }
    })
  }

  function handleRelease({ offset, velocity }) {
    velocityRef.current = velocity

    const sign = deriveDirectionSign({
      velocityThreshold: 0.1,
      offsetThreshold: height * 0.05,
      velocity,
      offset
    })

    api.start({
      y: open ? Y_OPENED : Y_CLOSED,
      immediate: false,
      config: { ...springConfig, velocity }
    })

    if (sign < 0) onOpen()
    else if (sign > 0) onClose()
  }

  function handleTap(e) {
    e.target.blur()
    open ? onClose() : onOpen()
  }
}
