import { useLocation } from '@kaliber/routing'
import { useMediaQuery } from '/machinery/useMediaQuery'
import { Drawer } from '/sub/drawer/Drawer'
import { Sidebar } from '/sub/sidebar/Sidebar'
import { useTheme } from '/machinery/Theme'
import styles from './Navigation.css'
import { viewportLg } from '/cssGlobal/media.css'

export function Navigation({ children }) {
  const isDesktop = useMediaQuery({ query: viewportLg, initialValue: false }) && 'desktop'
  const isMobile = useMediaQuery({ query: `not ${viewportLg}`, initialValue: false }) && 'desktop'

  return (
    <div className={cx(styles.component)}>
      {
        isDesktop ? <SidebarNavigation layoutClassName={styles.storySidebar} {...{ children }} /> :
        isMobile ? <DrawerNavigation {...{ children }} /> :
        null
      }
    </div>
  )
}

function SidebarNavigation({ children, layoutClassName = undefined }) {
  const theme = useTheme()

  return (
    <div className={cx(styles.componentSidebarNavigation, theme.background, theme.accent, layoutClassName)}>
      <Sidebar>
        {children}
      </Sidebar>
    </div>
  )
}

function DrawerNavigation({ children }) {
  const [drawerOpen, setDrawerOpen] = React.useState(false)
  const location = useLocation()

  React.useEffect(() => { setDrawerOpen(false) }, [location])

  return (
    <Drawer
      open={drawerOpen}
      onOpen={() => setDrawerOpen(true)}
      onClose={() => setDrawerOpen(false)}
      visible
      {...{ children }}
    />
  )
}
