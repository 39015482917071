import { routeMap } from '/routeMap'
import { useSettings } from '/machinery/Settings'
import { usePageRouteData } from '/machinery/PageRouteData'
import { ButtonListLink } from '/sub/button/ButtonListLink'
import { HeadingUppercaseXxl } from '/sub/typography/Heading'
import styles from './StorylineList.css'

export function StorylineList() {
  const { data } = usePageRouteData()
  const { storylines = [] } = data || {}
  const settings = useSettings()

  return (
    <div className={styles.component}>
      {storylines.length > 0 && <HeadingUppercaseXxl h={1}>
        {settings.title}
      </HeadingUppercaseXxl>}

      <ul className={styles.list}>
        {storylines.map(({ id, slug, title, description, image, overviewType, floors }) => {
          const to = overviewType === 'floor' && floors.length <= 1
            ? routeMap.storyline.floorPlan.floor({ storylineSlug: slug, floorSlug: floors[0].slug })
            : routeMap.storyline({ storylineSlug: slug })

          return (
            <li className={styles.listItem} key={id}>
              {<ButtonListLink dataX={`link-to-storyline-${slug}`} {...{ title, description, image, to }}>
                {title}
              </ButtonListLink>}
            </li>
          )
        })}
      </ul>
    </div>
  )
}
