import { Marker as ReactMapGlMarker } from 'react-map-gl'
import { Pin } from '/sub/Pin'

export function Marker({ latitude, longitude, active, title, onClick, dataX }) {
  return (
    <ReactMapGlMarker {...{ latitude, longitude }}>
      <Pin {...{ active, title, onClick, dataX }} />
    </ReactMapGlMarker>
  )
}
