import ReactPlayer from 'react-player/file'
import { Image } from '/sub/Image'
import { HeadingUppercaseXl } from '/sub/typography/Heading'
import { useTransition, animated, config } from '@react-spring/web'
import styles from './ChapterVideo.css'

export function ChapterVideo({ title, video, layoutClassName, running, active, onReadyChange, onDurationChange }) {
  const [ready, setReady] = React.useState(false)
  const [buffering, setBuffering] = React.useState(false)
  const transitions = useTransition(!ready || buffering, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.stiff
  })

  return (
    <div className={cx(styles.component, layoutClassName)}>
      <div className={styles.video}>
        {active && (
          <ReactPlayer
            playsinline
            muted
            playing={running}
            className={styles.videoLayout}
            url={video.src}
            onReady={handleReady}
            onBuffer={handleBufferStart}
            onBufferEnd={handleBufferEnd}
            onDuration={s => onDurationChange(s * 1000)}
            width='100%'
            height='100%'
          />
        )}

        {(!active || !ready) && video.poster && (
          <Image cover layoutClassName={styles.posterLayout} image={video.poster} />
        )}
      </div>
      <div className={styles.title}>
        <HeadingUppercaseXl>{title}</HeadingUppercaseXl>
      </div>
      {transitions((style, show) => show && <animated.div className={styles.buffering} {...{ style }} />)}
    </div>
  )

  function handleReady() {
    setReady(true)
    onReadyChange(true)
  }

  function handleBufferStart() {
    setBuffering(true)
    onReadyChange(false)
  }

  function handleBufferEnd() {
    setBuffering(false)
    onReadyChange(ready)
  }
}
