import { useTheme } from '/machinery/Theme'
import { Icon } from '/sub/Icon'
import iconArrowRightSmall from '/images/icons/arrow-right-small.raw.svg'
import iconArrowLeftSmall from '/images/icons/arrow-left-small.raw.svg'
import styles from './Button.css'
import { Link } from '@kaliber/routing'

export function ButtonPrimary({ children, to, dataX, layoutClassName = undefined }) {
  const theme = useTheme()

  return (
    <ButtonBase className={cx(layoutClassName, theme.root)} {...{ children, to, dataX }}>
      <span className={styles.text}>
        { children }
      </span>
      <span className={styles.iconRight}>
        <Icon icon={iconArrowRightSmall} />
      </span>
    </ButtonBase>
  )
}

export function ButtonAccent({ children, to, dataX, layoutClassName = undefined }) {
  const theme = useTheme()

  return (
    <ButtonBase className={cx(layoutClassName, theme.primary, theme.onPrimary)} {...{ children, to, dataX }}>
      <span className={styles.text}>
        { children }
      </span>
      <span className={styles.iconRight}>
        <Icon icon={iconArrowRightSmall} />
      </span>
    </ButtonBase>
  )
}

export function ButtonBack({ children, to, dataX, layoutClassName = undefined }) {
  return (
    <ButtonBase {...{ children, to, dataX, layoutClassName }}>
      <span className={styles.iconRight}>
        <Icon icon={iconArrowLeftSmall} />
      </span>
      <span className={styles.text}>{ children }</span>
    </ButtonBase>
  )
}

function ButtonBase({ children, to, dataX, className = undefined, layoutClassName = undefined }) {
  return (
    <Link
      anchorProps={{ className: cx(styles.componentBase, className, layoutClassName), 'data-x': dataX }}
      {...{ to }}
    >
      {children}
    </Link>
  )
}
