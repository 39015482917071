import iconMarkerOuter from '/images/icons/pin.raw.svg'
import iconMarkerActive from '/images/icons/iconMarkerActive.raw.svg'
import styles from './Pin.css'
import { useTheme } from '/machinery/Theme'

export function Pin({ active, onClick, title, dataX }) {
  const theme = useTheme()
  return (
    <button
      type='button'
      className={cx(styles.component)}
      aria-label={title}
      data-x={dataX}
      {...{ onClick }}
    >
      <div className={styles.layout}>
        <div
          className={cx(styles.outer, active && styles.isActive)}
          dangerouslySetInnerHTML={{ __html: iconMarkerOuter }}
        />
        <div className={cx(styles.circleIcon, theme.background, theme.accent, active && styles.isActive)} />
        <div
          className={cx(styles.icon, theme.accent, active && styles.isActive)}
          dangerouslySetInnerHTML={{ __html: iconMarkerActive }}
        />
      </div>
    </button>
  )
}
