import { useI18n } from '@kaliber/i18n'
import { usePick } from '@kaliber/routing'
import { useTransition, animated } from '@react-spring/web'
import { Image } from '/sub/Image'
import { HeadingMd } from '/sub/typography/Heading'
import { Screenreader } from '/sub/typography/Screenreader'
import { ButtonPrimary } from '/sub/button/Button'
import { Icon } from '/sub/Icon'
import styles from './StoryTeaser.css'
import iconClose from '/images/icons/close.raw.svg'

export function StoryTeaserAnimated({ story, storyContainerRoute, onDismiss }) {
  const transitions = useTransition(story, {
    from: { opacity: 1, transform: 'translate3d(0, 15vh, 1px)', width: '100%' },
    enter: { opacity: 1, transform: 'translate3d(0, 0vh, 1px)', width: '100%' },
    leave: { opacity: 0, transform: 'translate3d(0, 7.5vh, 0px)', position: 'absolute', bottom: 0, width: '100%' }
  })

  return (
    <div>
      {transitions(
        (style, story) => story && (
          <animated.div {...{ style }}>
            <StoryTeaser {... { story, storyContainerRoute, onDismiss }} />
          </animated.div>
        )
      )}
    </div>
  )
}

function StoryTeaser({ story: { title, description, image }, storyContainerRoute, onDismiss }) {
  const pick = usePick()
  const { route: currentStoryRoute } = pick(storyContainerRoute.story) || {}
  const detailUrl = currentStoryRoute?.detail() || '' // we might be animating out
  const i18n = useI18n()

  return (
    <div className={styles.component}>
      <div className={styles.content}>
        <HeadingMd>{title}</HeadingMd>
        <p className={styles.description}>{description}</p>
        <ButtonPrimary layoutClassName={styles.button} to={detailUrl} dataX='open-story'>
          <Screenreader>{i18n('open-story')}:</Screenreader>
          <span>{title}</span>
        </ButtonPrimary>
      </div>

      <div className={styles.cover}>
        <Image layoutClassName={styles.coverImage} cover {...{ image }} />
      </div>

      <button className={styles.dismiss} type='button' data-x='dismiss-story' onClick={onDismiss}>
        <Icon label={i18n('dismiss-story')} icon={iconClose} />
      </button>
    </div>
  )
}
