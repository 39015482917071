import { Image } from '/sub/Image'
import styles from './ChapterImage.css'

import { HeadingUppercaseXl } from '/sub/typography/Heading'

export function ChapterImage({ title, image, layoutClassName, onReadyChange }) {
  return (
    <div className={cx(styles.component, layoutClassName)}>
      <div className={styles.image}>
        <Image cover key={image.asset._id} onLoad={() => onReadyChange(true)} layoutClassName={styles.imageLayout} {...{ image }} />
      </div>

      <div className={styles.title}>
        <HeadingUppercaseXl>{title}</HeadingUppercaseXl>
      </div>
    </div>
  )
}
