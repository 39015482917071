import { useTheme } from '/machinery/Theme'
import { Image } from '/sub/Image'
import { HeadingMd } from '/sub/typography/Heading'
import { TextSm } from '/sub/typography/Text'
import styles from './ChapterImageWithText.css'

export function ChapterImageWithText({ title, text, image, layoutClassName, onReadyChange }) {
  const theme = useTheme()
  return (
    <div className={cx(styles.component, layoutClassName, theme.background)}>
      <div className={styles.image}>
        <Image cover onLoad={() => onReadyChange(true)} layoutClassName={styles.imageLayout} {...{ image }} />
      </div>

      <div className={cx(styles.details, theme.accent)}>
        <HeadingMd h={2}>{title}</HeadingMd>
        <TextSm>{text}</TextSm>
      </div>
    </div>
  )
}
