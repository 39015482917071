import React from 'react'

// Do not copy-paste this blindly, theming is under active development.
// Discuss in the Technology channel

const ThemeContext = React.createContext(null)

export function useTheme() {
  return React.useContext(ThemeContext)
}

export function ThemeProvider({ theme, children }) {
  return <ThemeContext.Provider value={theme} {...{ children }} />
}
