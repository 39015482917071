import ReactDOM from 'react-dom'
import { useTransition, useSpring, animated, config } from '@react-spring/web'
import { usePortal } from '/machinery/usePortal'
import { Icon } from '/sub/Icon'
import iconClose from '/images/icons/close.raw.svg'
import styles from './Modal.css'

function ModalBase({ className, visible, onClose, overlay, children }) {
  const clickStartedInsideRef = React.useRef(false)
  const elementRef = React.useRef(null)
  const portalNode = usePortal('_rootModal')
  const transitions = useTransition(visible, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.stiff
  })

  const animatedProps = useSpring({
    transform: visible ? 'scale(1)' : 'scale(0.95)',
    config: { tension: 400, friction: 50 }
  })

  React.useEffect(
    () => {
      if (!portalNode) return
      if (visible) elementRef.current.focus()
    },
    [portalNode, visible]
  )

  return portalNode && ReactDOM.createPortal(
    transitions((style, item) => item && (
      <animated.div
        ref={elementRef}
        className={cx(styles.component_rootBase, className)}
        onBlur={trapFocus}
        onPointerDown={handlePointerDown}
        onPointerMove={handlePointerMove}
        onPointerUp={handlePointerUp}
        {...{ style }}
      >
        <div className={styles.container}>
          <animated.div className={styles.content} style={animatedProps}>
            {children}
          </animated.div>
        </div>
        {overlay && <div className={styles.overlay}>
          {overlay}
        </div>}
      </animated.div>
    )),
    portalNode
  )

  function handlePointerDown(e) {
    e.stopPropagation()

    if (e.target !== e.currentTarget) {
      clickStartedInsideRef.current = true
    }
  }

  function handlePointerMove(e) {
    e.stopPropagation()
  }

  function handlePointerUp(e) {
    e.stopPropagation()

    if (!clickStartedInsideRef.current && e.target === e.currentTarget) {
      onClose()
    }

    clickStartedInsideRef.current = false
  }

  function trapFocus() {
    elementRef.current.focus()
  }
}

export function ModalPortrait({ visible, onClose, overlay = undefined, children }) {
  return (
    <ModalBase
      className={styles.componentPortrait}
      {...{ visible, onClose, overlay, children }}
    />
  )
}


export function ModalFullscreen({ visible, onClose, overlay = undefined, children }) {
  return (
    <ModalBase
      className={styles.componentFullscreen}
      overlay={overlay || (
        <div className={styles.ui}>
          <button className={styles.close} onClick={onClose} data-x='close-modal'>
            <Icon icon={iconClose} label="Sluiten" />
          </button>
        </div>
      )}
      {...{ visible, onClose, children }}
    />
  )
}
