import { asRouteMap } from '@kaliber/routing'
import groq from 'groq'
import { Home } from './pages/Home'
import { Storyline } from './pages/Storyline'
import { Page } from './pages/Page'
import { determineDocumentPath } from './machinery/determineDocumentPath'
import { Floor } from './sub/storyline/floor/Floor'
import { Overview as GeoOverview } from './sub/storyline/geo/Overview'

export const routeMap = asRouteMap({
  root: '',
  home: {
    path: ':language',
    data: { fetch: Home.fetchData },
  },
  page: {
    path: 'pagina/:pageSlug',
    data: {
      fetch: Page.fetchData,
      extractParams: { page: x => ({ pageSlug: extractSlug(x), language: x.language }) },
    },
  },
  storyline: {
    // TODO: in the ideal situation we want to redirect to `geo` or `floorPlan` based on the storyline overviewType. This would make it easier with the route context
    path: 'route/:storylineSlug',
    data: {
      fetch: Storyline.fetchData,
      extractParams: {
        storyline: x => ({ storylineSlug: extractSlug(x), language: x.language }),
      },
    },

    // TODO: kunnen we dit wisselen? geo.storyline en floorPlan.storyline? Dat betekent dat de permalink anders is op basis van het overviewType. Zou matching een stuk makkelijker maken omdat componenten dan weten in welke context ze zich bevinden (zou storyContainerRoute onnodig maken)
    geo: {
      path: 'landkaart',

      story: {
        path: ':storySlug',
        data: {
          fetch: GeoOverview.fetchStoryData,
          extractParams: {
            geoStory: async (x, { client }) => ({
              storySlug: extractSlug(x),
              storylineSlug: await fetchSlug(x.storyline, { client })
            })
          }
        },

        detail: 'detail'
      }
    },
    floorPlan: {
      path: 'plattegrond',

      floor: {
        path: ':floorSlug',
        data: {
          fetch: Floor.fetchData,
          extractParams: {
            floor: async (x, { client }) => ({
              floorSlug: extractSlug(x),
              storylineSlug: await fetchSlug(x.storyline, { client }),
            })
          }
        },

        story: {
          path: ':storySlug',
          data: {
            fetch: Floor.fetchStoryData,
            extractParams: {
              floorStory: async (x, { client }) => ({
                storySlug: extractSlug(x),
                floorSlug: await fetchSlug(x.floorLocation.floor, { client }),
                storylineSlug: await waterfall(
                  fetchDocument(x.floorLocation.floor, { client }),
                  async x => fetchSlug(x.storyline, { client })
                ),
              })
            }
          },

          detail: 'detail'
        }
      }
    }
  },
  api: {
    path: 'api',

    v1: {
      path: 'v1',

      url: {
        path: 'document/path',
        data: { fetch: determineDocumentPath },
      }
    },
  },
  admin: 'admin',
  notFound: '*',
})

function extractSlug(x) { return x.slug.current }
async function fetchSlug({ _ref }, { client }) {
  const { slug } = await client.fetch(groq`*[_id == $id][0] { 'slug': slug.current }`, { id: _ref })
  return slug
}
async function fetchDocument({ _ref }, { client }) {
  return client.fetch(groq`*[_id == $id][0] { ... }`, { id: _ref })
}
async function waterfall(input, ...functions) {
  return functions.reduce(async (result, f) => f(await result), await input)
}
