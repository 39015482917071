import { I18nProvider } from '@kaliber/i18n'
import groq from 'groq'
import { i18n } from '/i18n'
import { usePageRouteData } from '/machinery/PageRouteData'
import { useSettings } from '/machinery/Settings'
import { DraggableMap } from '/sub/draggableMap/DraggableMap'
import styles from './Home.css'

Home.fetchData = async ({ client, params }) => {
  if (!['nl', 'en'].includes(params.language)) return null

  const storylines = await client.fetch(
    groq`
      *[_type == 'storyline' && language == $language && (!defined(hideOnFrontPage) || !hideOnFrontPage)]
      {
        ...,
        'id': _id,
        'slug': slug.current,
        'floors': *[_type == 'floor' && storyline._ref == ^._id] { 'slug': slug.current }
      }
    `,
    { language: params.language }
  )
  return { storylines }
}

export function Home({ language }) {
  const settings = useSettings()

  return (
    <I18nProvider value={i18n} {...{ language }} >
      <div
        className={styles.page}
        style={{ backgroundColor: settings.image.asset.metadata.palette.dominant.background }}
      >
        <div className={styles.map}>
          <DraggableMap image={settings.image} offsetX={0} offsetY={0} />
        </div>
      </div>
    </I18nProvider>
  )
}

