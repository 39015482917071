import { useRoutes } from '@kaliber/routing'
import { usePageRouteData } from '/machinery/PageRouteData'
import { ButtonListLink } from '/sub/button/ButtonListLink'
import { HeadingSm, HeadingUppercaseXl } from '/sub/typography/Heading'
import styles from './StoryList.css'

export function StoryList() {
  const routes = useRoutes()
  const { data } = usePageRouteData()

  const {
    storyline = null,
    stories = [],
    floors = [],
    floor = null,
  } = data || {}

  const overviewType = storyline?.overviewType ?? null

  const containerRoute =
    overviewType === 'floor' ? routes.floorPlan.floor :
    overviewType === 'geo' ? routes.geo :
    null

  return (
    <div className={styles.component}>
      {(floors.length > 0 || stories.length > 0) && <header className={styles.header}>
        <HeadingUppercaseXl h={1}>
          {storyline?.title ?? ''}
        </HeadingUppercaseXl>

        {(floors.length > 1 && floor) && (
          <HeadingSm>
            {floor.title}
          </HeadingSm>
        )}
      </header>}

      {(floors.length > 1 && !floor)
        ? <ListOfFloors {...{ floors, containerRoute }} />
        : <ListOfStories {...{ stories, containerRoute }} />
      }

    </div>
  )
}

function ListOfFloors({ floors, containerRoute }) {
  return (
    <ul className={cx(styles.componentListOfFloors, styles.list)}>
      {floors.map(({ id, slug, title, description, image }) => {
        const to = containerRoute({ floorSlug: slug })

        return (
          <li className={styles.listItem} key={id}>
            {<ButtonListLink dataX={`link-to-floor-${slug}`} {...{ title, description, image, to }}>
              {title}
            </ButtonListLink>}
          </li>
        )
      })}
    </ul>
  )
}

function ListOfStories({ stories, containerRoute }) {
  return (
    <ul className={cx(styles.componentListOfStories, styles.list)}>
      {stories.map(({ id, slug, title, description, image }) => {
        const to = containerRoute.story({ storySlug: slug })

        return (
          <li className={styles.listItem} key={id}>
            {<ButtonListLink dataX={`link-to-story-${slug}`} {...{ title, description, image, to }}>
              {title}
            </ButtonListLink>}
          </li>
        )
      })}
    </ul>
  )
}
