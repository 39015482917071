import { animated } from  '@react-spring/web'
import { useElementSize } from '@kaliber/use-element-size'
import { useImageBuilder } from '/machinery/Sanity'
import styles from './Image.css'
import { useIsMountedRef } from '@kaliber/use-is-mounted-ref'

export function Image({ cover = undefined, image, aspectRatio = undefined, onLoad = undefined, layoutClassName = undefined, draggable = true, quality = 75 }) {
  const [loaded, setLoaded] = React.useState(false)
  const { size: { width = 0 }, ref } = useElementSize()
  const { thumb, src, srcSet } = useSrcSet({ image, aspectRatio, quality })
  const isMountedRef = useIsMountedRef()

  return (
    <div {...{ ref }} className={cx(styles.component, width && styles.hasSize, layoutClassName)}>
      {!loaded && <animated.img
        key={width ? 'thumb' : 'image'}
        className={cx(styles.thumb, cover && styles.isCover)}
        srcSet={`${thumb} 1w`}
        sizes='1px'
        alt={width ? '' : image.alt}
        draggable={draggable ? 'true' : 'false'}
        {...{ src }}
      />}

      {width && <img
        key='image'
        className={cx(styles.image, cover && styles.isCover)}
        sizes={width + 'px'}
        alt={image.alt}
        width={aspectRatio ? 100 : image.asset.metadata ? image.asset.metadata.dimensions.width : undefined}
        height={aspectRatio ? 100 / aspectRatio : image.asset.metadata ? image.asset.metadata.dimensions.height : undefined}
        onLoad={handleLoad}
        draggable={draggable ? 'true' : 'false'}
        style={{ objectPosition: image.hotspot ? `${image.hotspot.x * 100}% ${image.hotspot.y * 100}%` : undefined }}
        {...{ src, srcSet }}
      />}
    </div>
  )

  function handleLoad(e) {
    if (!isMountedRef.current) return
    setLoaded(true)
    onLoad && onLoad(e)
  }
}

const defaultSizes = [320, 480, 720, 1024, 1440, 1920]
function useSrcSet({ image, aspectRatio, quality, sizes = defaultSizes }) {
  const builder = useImageBuilder()
  return React.useMemo(
    () => {
      const [src] = sizes.slice(-1).map(x => builder.image(image).width(x).auto('format').url())
      const thumb = image.asset.metadata
        ? image.asset.metadata.lqip
        : builder.image(image).width(20).quality(0).blur(20).auto('format').url()
      const srcSet = aspectRatio
        ? sizes.map(x => `${builder.image(image).width(x).height(Math.round(x / aspectRatio)).quality(quality).auto('format').url()} ${x}w`).join(', ')
        : sizes.map(x => `${builder.image(image).width(x).quality(quality).auto('format').url()} ${x}w`).join(', ')

      return { thumb, src, srcSet }
    },
    [image, aspectRatio, sizes, quality, builder]
  )
}
