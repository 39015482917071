import { useTheme } from '/machinery/Theme'
import { HeadingUppercaseXxl } from '/sub/typography/Heading'
import { Lottie } from '/sub/Lottie'
import styles from './ChapterAnimatedQuote.css'

export function ChapterAnimatedQuote({ text, animation, layoutClassName, onReadyChange, color }) {
  const theme = useTheme()
  const [animationLoaded, setAnimationLoaded] = React.useState(false)
  React.useEffect(
    () => { onReadyChange(!animation || animationLoaded) },
    [animation, animationLoaded, onReadyChange]
  )

  return (
    <div className={cx(styles.component, layoutClassName, theme.background, theme.accent)}>
      {animation && (
        <div className={styles.animation}>
          <Lottie json={animation} onLoad={() => setAnimationLoaded(true)} layoutClassName={styles.lottie} />
        </div>
      )}
      
      <div className={styles.title}>
        <HeadingUppercaseXxl>{text}</HeadingUppercaseXxl>
      </div>
    </div>
  )
}
