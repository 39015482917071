const MapPositionContext = React.createContext(null)

export function MapPositionContextProvider({ children }) {
  const positionRef = React.useRef({ x: 0, y: 0 })

  return <MapPositionContext.Provider value={positionRef}>{children}</MapPositionContext.Provider>
}

export function useMapPosition() {
  const positionRef = React.useContext(MapPositionContext)

  return {
    getPosition: React.useCallback(() => positionRef.current, []),
    setPosition: React.useCallback(position => { positionRef.current = position }, [])
  }
}
