import { animated } from '@react-spring/web'
import { useI18n } from '@kaliber/i18n'
import { useTheme } from '/machinery/Theme'
import { useAnimatedValue } from '/machinery/useAnimatedValue'
import { useRequestAnimationFrameProgressTracker } from '/machinery/useRequestAnimationFrameProgressTracker'
import { Chapter360 } from '/sub/story/chapters/Chapter360'
import { ChapterAnimatedQuote } from '/sub/story/chapters/ChapterAnimatedQuote'
import { ChapterImage } from '/sub/story/chapters/ChapterImage'
import { ChapterImageWithText } from '/sub/story/chapters/ChapterImageWithText'
import { ChapterVideo } from '/sub/story/chapters/ChapterVideo'
import { ChapterLink } from '/sub/story/chapters/ChapterLink'
import iconClose from '/images/icons/close.raw.svg'
import styles from './StorySlide.css'
import { useSettings } from '/machinery/Settings'

export function StorySlide({
  active,
  onNext,
  onClose,
  running,
  onRunningChange,
  chapters,
  chapterIndex,
  title,
  description = undefined,
}) {
  const settings = useSettings()
  const defaultDuration = settings.transitionDelay || 8000

  const chapter = chapters[chapterIndex]
  const [ready, setReady] = useChapterReady({ chapters, chapterIndex })
  const [duration, setDuration] = React.useState(defaultDuration)
  const [progress, setProgress] = useAnimatedValue('progress', 0)
  React.useMemo(
    () => setDuration(chapter.transitionDelay || defaultDuration),
    [chapter.transitionDelay, defaultDuration]
  )

  // TODO: Refactor. Example: Veteranen Instituut ArticleCarouselFeatured
  const { start, pause, reset } = useRequestAnimationFrameProgressTracker({
    duration,
    onTick: p => setProgress(p + chapterIndex),
    onComplete: onNext,
  })

  React.useEffect(
    () => {
      if (!active) return
      reset()
      start()
    },
    [active, chapterIndex, reset, start]
  )

  React.useEffect(
    () => { (active && ready && running) ? start() : pause() },
    [active, ready, running, start, pause]
  )

  return (
    <div
      className={styles.component}
      onContextMenu={e => /* TODO: this is not handy during development */ e.preventDefault()}
    >
      <div className={styles.ui}>
        <Header
          pagination={
            <Pagination count={chapters.length} {...{ progress }} />
          }
          {...{ title, description, onClose }}
        />
      </div>

      {
      /* There are 3 states that affect wether a chapter is running:
       * - active: wether the story is in focus
       * - ready: wether the media for this chapter is ready
       * - running: wether this chapter should be running, based on interaction
       *
       * A chapter can call...
       * - ...onReadyChange(false) when it needs to load more data
       * - ...onRunningChange(false) when it needs to pause autoplay, regardless of data
       */
      }

      {chapter.chapterType === '360' && <Chapter360
        layoutClassName={styles.chapter}
        title={chapter.title}
        image360={chapter.image360}
        onReadyChange={setReady}
        {...{ onRunningChange }}
      />}
      {chapter.chapterType === 'animatedQuote' && <ChapterAnimatedQuote
        layoutClassName={styles.chapter}
        text={chapter.text}
        animation={chapter.animation}
        onReadyChange={setReady}
      />}
      {chapter.chapterType === 'image' && <ChapterImage
        layoutClassName={styles.chapter}
        title={chapter.title}
        image={chapter.image}
        onReadyChange={setReady}
      />}
      {chapter.chapterType === 'imageWithText' && <ChapterImageWithText
        layoutClassName={styles.chapter}
        title={chapter.title}
        text={chapter.text}
        image={chapter.image}
        onReadyChange={setReady}
      />}
      {chapter.chapterType === 'video' && <ChapterVideo
        layoutClassName={styles.chapter}
        title={chapter.title}
        video={chapter.video}
        onReadyChange={setReady}
        onDurationChange={setDuration}
        running={active && ready && running}
        {...{ active }}
      />}
      {chapter.chapterType === 'link' && <ChapterLink
        layoutClassName={styles.chapter}
        title={chapter.title}
        link={chapter.link}
        onReadyChange={setReady}
      />}
    </div>
  )
}

function Header({ pagination, onClose, title, description }) {
  const i18n = useI18n()
  const theme = useTheme()

  return (
    <div className={styles.componentHeader}>
      <div className={styles.header}>
        <h2 className={styles.heading}>{title}</h2>
        <p className={styles.description}>{description}</p>
      </div>

      <div className={cx(styles.avatar, theme.background, theme.accent)}>
        <svg width="38" height="38" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.69652384,19 C6.69652384,25.7838032 12.2160683,31.3031405 19.0000865,31.3031405 C25.7841047,31.3031405 31.30313,25.7838032 31.30313,19 L31.30313,19 L33,19 C33,26.7197329 26.7196941,33 19.0000865,33 C11.2801328,33 5,26.7197329 5,19 L5,19 Z M11.0350551,19 C11.0350551,23.3919048 14.6081873,26.9649741 19,26.9649741 C23.3918127,26.9649741 26.9649449,23.3919048 26.9649449,19 L26.9649449,19 L28.625,19 C28.625,24.3071037 24.3071971,28.625 19,28.625 C13.6928029,28.625 9.375,24.3071037 9.375,19 L9.375,19 Z" fill="currentColor" fillRule="evenodd" />
        </svg>
      </div>

      <button className={styles.close} onClick={onClose} aria-label={i18n('close-story')} data-x='close-story'>
        <span dangerouslySetInnerHTML={{ __html: iconClose }} />
      </button>

      <div className={styles.pagination}>
        {pagination}
      </div>
    </div>
  )
}

function Pagination({ count, progress }) {
  const range = Array.from(Array(count).keys())

  return (
    <div className={styles.componentPagination}>
      {range.map(i => (
        <div key={i} className={styles.item}>
          <animated.div
            className={styles.fill}
            style={{ transform: progress.interpolate(p =>
              i > p ? `scaleX(0)` :
              p - i < 1 ? `scaleX(${ p - i })` :
              `scaleX(1)`)
            }}
          />
        </div>
      ))}
    </div>
  )
}

function useChapterReady({ chapters, chapterIndex }) {
  const [ready, setReady] = React.useState(() => chapters.map(() => false))

  const chapterReady = ready[chapterIndex]
  const setChapterReady =  React.useCallback(
    chapterReady => {
      setReady(ready => [
        ...ready.slice(0, chapterIndex),
        chapterReady,
        ...ready.slice(chapterIndex + 1)
      ])
    },
    [chapterIndex]
  )

  return [chapterReady, setChapterReady]
}
