import groq from 'groq'
import { useNavigate, useRoutes } from '@kaliber/routing'
import { animated } from '@react-spring/web'
import { usePageRouteData } from '/machinery/PageRouteData'
import { useMediaQuery } from '/machinery/useMediaQuery'
import { DraggableMap } from '/sub/draggableMap/DraggableMap'
import { viewportMd } from '/cssGlobal/media.css'
import styles from './Floor.css'

Floor.fetchData = async function fetchData({ client, params: { floorSlug }, parentData: { floors } }) {
  const floor = floors.find(x => x.slug === floorSlug)
  if (!floor) return null

  const stories = await fetchFloorStories(client, floor.id)
  return { floor, stories }
}

async function fetchFloorStories(client, floorId) {
  return client.fetch(
    groq`
      *[_type == 'floorStory' && floorLocation.floor._ref == $floorId]
      {
        ...,
        'id': _id,
        'slug': slug.current,
        'description': floorLocation.floor->title,
        'floor': {
          'id': floorLocation.floor._ref,
          'title': floorLocation.floor->title
        },
        'floorLocation': {
          'location': floorLocation.location { x, y }
        },
        image {
          asset->
        },
        chapters[] {
          ...,
          'id': _key,
          image { ..., asset-> },
          image360 {
            ...,
            poster { ..., asset-> },
            image { ..., asset-> }
          },
          video {
            ...,
            'src': src.asset->url,
            poster { ..., asset-> }
          },
          link {
            ...,
            'url': coalesce(internal->permalink, external)
          }
        }
      } |
      order(documentOrder desc)
    `,
    { floorId })
}

Floor.fetchStoryData = function fetchData({ params: { storySlug }, parentData: { stories } }) {
  const story = stories.find(x => x.slug === storySlug)
  if (!story) return null

  return { story }
}

export function Floor({ showDetailOnClick, image, active, visible, onClick = undefined }) {
  /** This type is only correct for the active floor
    @type {import('/routeMap').routeMap['storyline']['floorPlan']['floor']} */
  const routes = useRoutes()
  const navigate = useNavigate()
  const { data } = usePageRouteData()
  const { stories = [], story: selectedStory = null } = data || {}
  const isViewportMd = useMediaQuery({ query: viewportMd, initialValue: false })

  return (
    <animated.div
      className={styles.component}
      data-context-visible={visible && 'true'}
    >
      <div
        className={styles.floorButton}
        onClick={e => onClick && onClick(e)}
      >
        <DraggableMap
          pins={(active && visible) ? stories : []}
          onPinClick={handleStoryClick}
          selectedPin={selectedStory}
          enabled={active}
          offsetX={isViewportMd ? 160 : 0}
          offsetY={isViewportMd ? 0 : 180}
          {...{ image }}
        />
      </div>
    </animated.div>
  )

  function handleStoryClick(story) {
    const targetRoute = showDetailOnClick ? routes.story.detail : routes.story
    navigate(targetRoute({ storySlug: story.slug }), { state: { preventPinFocus: true } }) // it would be tough to get typing right
  }
}
