import { usePageRouteData } from '/machinery/PageRouteData'
import { ThemeProvider } from '/machinery/Theme'
import { Section } from '/sub/layout/Section'
import { Container } from '/sub/layout/Container'
import { HeadingUppercaseXl } from '/sub/typography/Heading'
import { Code } from '/sub/Code'
import theme from '/themes/red-light.css'

export function ServerError() {
  const { data } = usePageRouteData()
  const { title = '', stack = '' } = data

  return (
    <ThemeProvider {...{ theme }}>
      <div className={theme.root}>
        <Section>
          <Container>
            <HeadingUppercaseXl h={1}>{title}</HeadingUppercaseXl>
            <Code>{stack}</Code>
          </Container>
        </Section>
      </div>
    </ThemeProvider>
  )
}
