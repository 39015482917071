import blueDark from './blue-dark.css'
import brown from './brown.css'
import grayLight from './gray-light.css'
import gray from './gray.css'
import greenLight from './green-light.css'
import green from './green.css'
import redDark from './red-dark.css'
import redLight from './red-light.css'
import yellowLight from './yellow-light.css'
import yellow from './yellow.css'

export const themes = {
  blueDark,
  brown,
  grayLight,
  gray,
  greenLight,
  green,
  redDark,
  redLight,
  yellowLight,
  yellow,
}
